/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-03-08",
    versionChannel: "nightly",
    buildDate: "2025-03-08T00:05:36.092Z",
    commitHash: "f0ed1ef1672b2a7db75cd1bd5fbedb8d20c64224",
};
